// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
// This file is replaced during build by using the `fileReplacements` array.
// `ng build -c staging-sms` replaces `environment.ts` with `sms/environment.staging.ts`.
// `ng build -c staging` replaces `environment.ts` with `environment.staging.ts`. *** Identic version
// The list of file replacements can be found in `angular.json`.
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --staging` replaces `environment.ts` with `environment.staging.ts`.
// The list of file replacements can be found in `angular.json`.

// import { LogLevel } from "angular-auth-oidc-client";

export const environment: any = {
  production: false,
  ADMIN_ROLE: 'Administrators',
  ANON_TEMPLATE_API_URL: 'https://servicesstaging.identic.com.au/API/Template/v1.0',  // For anon-templates
  API_BASE_URL: 'https://servicesstaging.identic.com.au/API/Services/v1.0',
  APP_NAME: 'Services',
  // AUTH_LOG_LEVEL: LogLevel.Debug,
  IDSVR4_API_CLIENT: 'SERVICES.UI.Test',
  IDSVR4_API_SCOPE: 'SERVICES.API.Test',
  // ROUTE_DEBUG: true,
  SECURE_IMAGE_API_URL: 'https://servicesstaging.identic.com.au/API/MediaStore/v1.0/Secure',
  SITE_LOGO: 'logo-identic.jpg',
  SITE_SHORT_TITLE: 'Services',
  SITE_TITLE: 'Identic Services',
  SSO_SERVER_URL: 'https://identitystaging.identic.com.au',
  // STORAGE: 'local',  // 'local' | 'session' | 'cookie';  // defaults to 'local'
  // UI_URL: 'https://servicesstaging.identic.com.au/staging/UI/Service',
  VIRTUAL_DIR: '/',
  Api: {
    IdenticApp: '',//'Services'
  }
};

export const DYNAMIC_QUESTIONNAIRE_BASE_TEMPLATE = `
{
  "version": "1.0.1",
  "stepButtons": {
      "restart": {
          "title": "Reset application form",
          "leftIcon": {
              "faIcon": "fa fa-undo"
          },
          "colour": "primary"
      },
      "back": {
          "label": "Back",
          "leftIcon": {
              "matIcon": "navigate_before"
          },
          "colour": "primary"
      },
      "next": {
          "label": "Next",
          "rightIcon": {
              "matIcon": "navigate_next"
          },
          "colour": "primary"
      },
      "complete": {
          "label": "Submit",
          "rightIcon": {
              "matIcon": "cloud_upload"
          },
          "title": "Submit this application",
          "class": "bg-success text-light"
      }
  },
  "questions": []
}
`;
import { Component } from '@angular/core';

import { ConfigService } from '@identic/core';

@Component({
  selector: 'app-root',
  template: `
<app-layout [title]="title" [shortTitle]="shortTitle" [siteLogoUrl]="siteLogoUrl"></app-layout>`
})
export class AppComponent {
  title = this.config.environment.SITE_TITLE;
  shortTitle = this.config.environment.SITE_SHORT_TITLE;
  siteLogoUrl = `assets/${this.config.environment.SITE_LOGO}`;

  constructor(private config: ConfigService) {}
}

import { Routes } from '@angular/router';
import { AuthGuard } from '@identic/auth';

import { adminRole, editorRole, readOnlyRole } from '@identic/core';
import { EMAIL_ROLE } from '@identic/email';
import { MenuItems } from '@identic/layout';
import { IDENTIC_PRODUCT_SELECTOR_PATH, IdenticProductSelectorComponent, licenceMenuItems } from '@identic/licence';
import { LOGTODB_VIEWER_ROLE } from '@identic/log-to-db';
import { QUESTIONNAIRE_ROLE } from '@identic/questions';
import { SCHEDULER_ROLE } from '@identic/scheduler';
import { TEMPLATE_ROLE } from '@identic/templates';
import { DYNAMIC_QUESTIONNAIRE_BASE_TEMPLATE } from '../assets/dynamic-questionnaire.base-template';
import { environment } from '../environments';
import { HomeComponent } from './home.component';

const APP_ADMIN_ROLE = adminRole(environment.APP_NAME);

// Page options are here and referenced twice below in Routes & menuItems
// E.G. appointments: { text: 'Appointments', icon: 'people', path: 'appointments', routerLink: '/appointments/list', roles: 'SMS.Admin&&(Unit.EDU||Unit.SLC)' },

const Page = {
  // Custom options
  applications: { text: 'Applications', icon: 'adb', path: 'identic-app', routerLink: 'identic-app/list', roles: APP_ADMIN_ROLE },

  // Core options
  home: { text: 'Home', icon: 'home', path: 'home', routerLink: 'home', application: 'services' },
  applicationSelect: { text: 'Change Application', icon: 'swap_horizontal_circle', path: IDENTIC_PRODUCT_SELECTOR_PATH, routerLink: IDENTIC_PRODUCT_SELECTOR_PATH, roles: APP_ADMIN_ROLE },
  emailLogs: { text: 'Email Logs', icon: 'library_books', path: 'logs/group/email', routerLink: 'logs/group/Email', roles: editorRole(EMAIL_ROLE) },
  emailTemplates: { text: 'Email Template Edit', icon: 'email', path: 'email-template', routerLink: 'email-template/list', roles: editorRole(EMAIL_ROLE) },
  licences: { text: 'Licences', faIcon: 'fas fa-file-certificate fa-fw', path: 'licence', /*routerLink: 'licence',*/ loggedInOnly: true, children: licenceMenuItems },
  logs: { text: 'Logs', icon: 'list', path: 'logs', routerLink: 'logs/list', roles: `${APP_ADMIN_ROLE}||${LOGTODB_VIEWER_ROLE}` },
  notifications: { text: 'Notifications', icon: 'record_voice_over', path: 'notifications', routerLink: 'notifications/list', loggedInOnly: true },
  pageTemplates: { text: 'Template Edit', icon: 'web', path: 'page-template', routerLink: 'page-template/list', roles: editorRole(TEMPLATE_ROLE) },
  questionnaireTemplates: { text: 'Questionnaire Edit', icon: 'help', path: 'dynamic-questions', routerLink: 'dynamic-questions/list', questionnaireTemplate: DYNAMIC_QUESTIONNAIRE_BASE_TEMPLATE, roles: editorRole(QUESTIONNAIRE_ROLE) },
  schedulerAdmin: { text: 'Scheduler Admin', icon: 'schedule', path: 'scheduler', routerLink: 'scheduler/list', roles: editorRole(SCHEDULER_ROLE) },
  schedulerLogs: { text: 'Scheduler Logs', icon: 'library_books', path: 'scheduler', routerLink: 'scheduler/logs', roles: readOnlyRole(SCHEDULER_ROLE) },
  schedulerUser: { text: 'Scheduler', icon: 'schedule', path: 'scheduler', routerLink: 'scheduler/user', roles: editorRole(SCHEDULER_ROLE) },
};

export const appRoutes: Routes = [
  // Custom options
  { path: Page.applications.path, loadChildren: () => import('@identic/identic-app').then(m => m.IdenticAppModule), data: Page.applications },

  // Core options
  { path: Page.home.path, redirectTo: '', pathMatch: 'full' },
  { path: '', component: HomeComponent, data: Page.home },
  { path: 'auth-'+Page.applicationSelect.path, component: IdenticProductSelectorComponent, data: Page.applicationSelect, canActivate: [AuthGuard] },  // For direct links to force login first
  { path: Page.applicationSelect.path, component: IdenticProductSelectorComponent, data: Page.applicationSelect },
  { path: Page.emailLogs.path, loadChildren: () => import('@identic/log-to-db').then(m => m.LogToDbModule), data: Page.emailLogs },
  { path: Page.emailTemplates.path, loadChildren: () => import('@identic/email').then(m => m.EmailTemplateModule), data: Page.emailTemplates },
  { path: Page.licences.path, loadChildren: () => import('@identic/licence').then(m => m.LicenceModule), data: Page.licences },
  { path: Page.logs.path, loadChildren: () => import('@identic/log-to-db').then(m => m.LogToDbModule), data: Page.logs },
  { path: Page.notifications.path, loadChildren: () => import('@identic/notification').then(m => m.NotificationModule), data: Page.notifications },
  { path: Page.pageTemplates.path, loadChildren: () => import('@identic/templates').then(m => m.PageTemplateModule), data: Page.pageTemplates},
  { path: Page.questionnaireTemplates.path, loadChildren: () => import('@identic/questions').then(m => m.DynamicQuestionnaireEditorModule), data: Page.questionnaireTemplates },
  { path: Page.schedulerAdmin.path, loadChildren: () => import('@identic/scheduler').then(m => m.SchedulerModule), data: Page.schedulerAdmin},
];

export const menuItems: MenuItems = [
  // User profile option
  {
    text: '',             // userDisplayName
    // path?: string;       // Profile path
    // routerLink?: string; // Profile path
    icon: 'person',
    title: 'User',
    isProfile: true,
  },

  Page.home,
  Page.applications,
  Page.licences,
  Page.logs,
  Page.questionnaireTemplates,
  Page.notifications,
  Page.emailTemplates,
  Page.emailLogs,
  Page.pageTemplates,
  Page.schedulerAdmin,
  Page.schedulerLogs,
  Page.schedulerUser,
];
